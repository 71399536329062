<template>
<div>
    <div v-if="loaded">
        <template v-if="validCertificate">
            <div class="valid">
                <div class="mt-3 mb-3 text-center">
                    <img src="@/assets/logo.png" />
                </div>
                <div class="d-flex justify-content-center">
                <div class="bg-light pt-3 mycard shadow p-3 rounded">
                    <div class="text-center">
                        <p class="btn btn-sm btn-success">Certificado válido</p>
                    </div>
                    <div v-if="multiple">
                        <table class="table table-sm table-hover" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th>Estudiante</th>
                                    <th>Curso</th>
                                    <th>Completado el</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cert,index) in certificate" :key="index">
                                    <td>{{cert.fullname}}</td>
                                    <td>{{cert.tipo}} - {{cert.course_name}}</td>
                                    <td class="text-right">{{cert.completed_on}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <h4>Certificado Id</h4>
                        <p>{{id}}</p>
                        <h4>Nombre</h4>
                        <p>{{certificate.fullname}}</p>
                        <h4>Completado el</h4>
                        <p>{{certificate.completed_on}}</p>
                        <h4>Programa</h4>
                        <p>{{certificate.course_name}}</p>
                    </div>
                </div>
                </div>
                <div class="text-center mt-3 text-footer">
                    Consultado el {{lastDate}}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="valid">
                <div class="mt-3 mb-3 text-center">
                    <img src="@/assets/logo.png" />
                </div>
                <div class="d-flex justify-content-center">
                <div class="bg-light pt-3 mycard shadow p-3 rounded">
                    <div class="text-center">
                    <p class="btn btn-sm btn-danger">Certificado inválido</p>
                    </div>
                    <h4>Certificado Id</h4>
                    <p>{{id}}</p>
                </div>
                </div>
                <div class="text-center mt-3 text-footer">
                    Consultado el {{lastDate}}
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'

export default {
    props: ['id'],
    data: function () {
        return {
            validCertificate: null,
            loaded : false,
            certificate: {},
            lastDate: null,
            multiple: false
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_APIURL + this.id)
        .then(response =>{
            this.lastDate = moment().format('Do MMMM YYYY, h:mm');
            this.loaded =  true;
            if (response.error) {
                this.validCertificate   = false;
            } else{
                if(response.data.data.length > 1) {
                    this.multiple =  true
                    this.validCertificate   = true;
                    this.certificate        = response.data.data
                } else {
                     this.multiple =  false
                    this.validCertificate   = true;
                    if(Array.isArray(response.data.data)) {
                        this.certificate        = response.data.data[0]
                    } else {
                        this.certificate        = response.data.data
                    }
                }
            }


        });

    }
}
</script>

<style>
body{
    background:#eaeaea
}
.mycard{
    min-width:350px
}
.text-footer{font-size:12px}
</style>